import React from 'react';
import {HeadFC, PageProps, navigate} from 'gatsby';
import {PageContextType} from '../../types/page-context.type';
import Layout from '../../components/Layout';
import {StaticImage} from 'gatsby-plugin-image';
import CustomLink from '../../components/shared/custom-link/CustomLink';

const browser = typeof window !== "undefined" && window;

type ThankYouProps = {
  title: string;
  description: string;
}

const ThankYou = ({location}: PageProps<null, PageContextType>) => {
  if (!browser) {
    return;
  }

  if (!location.state) {
    return navigate('../');
  }

  const {title, description} = location.state as ThankYouProps;

  return (
    <Layout>
      <section className={'p-6 sm:p-20 md:p-28 flex justify-center bg-neutrals-90'}>
        <div className={'flex flex-col justify-center items-center bg-white px-8 sm:px-12 md:px-20 py-10 sm:py-16 md:py-28 rounded-md shadow-sm'}>
          <StaticImage className={'mb-5 w-24 sm:w-28 h-24 sm:h-28'} loading={'eager'} placeholder={'blurred'} src={'../../assets/check_icon.png'} alt={''}/>
          <h1 className={'text-2xl sm:text-3xl md:text-4xl mb-1.5 text-center sm:text-baseline'}>{title}</h1>
          <p className={'text-sm sm:text-base md:text-lg text-neutrals-40 mb-5 text-center sm:text-baseline'} dangerouslySetInnerHTML={{__html: description}} />
          <CustomLink isInternal to={'../'} className={'mtr-btn-[secondary] w-fit mtr-btn sm:mtr-btn-large'}>
            <span className={'mtr-icon'}>arrow_back</span>
            <span>Powrót</span>
          </CustomLink>
        </div>
      </section>
    </Layout>
  )
}

export default ThankYou;

export const Head: HeadFC = () =>
  <>
    <title>Dziękujemy - MYTRACKO</title>
    <meta name="robots" content="noindex, nofollow"/>
  </>
